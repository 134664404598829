import { useEffect } from 'react';
import useQueryParams from './useQueryParams';
import useRESTAPI from './RESTAPI';

const useQueryParamsRESTAPI = (endpoint: string) => {
  const { queryParams, setQueryParams } = useQueryParams();
  const { items, fetchItems, fetchItem, addItem, editItem, removeItem, hasFetchedSuccessfully, pagination } = useRESTAPI(endpoint);


  const updateItems = ()=>
  {

    fetchItems(queryParams);
  } 
  const updateQueryParams = (params: { [key: string]: any }) => {
    setQueryParams({ ...queryParams, ...params });
  };

  return { 
    items, 
    updateItems, 
    hasFetchedSuccessfully, 
    pagination, 
    queryParams, 
    setQueryParams: updateQueryParams 
  };
};

export default useQueryParamsRESTAPI;
