import React, { useEffect } from 'react';

export interface TableColumn {
  key: string;
  label: string;
  width?: number;
}
export interface TableData {
  [key: string]: any;
}
export interface TableComponentProps {
  columns: TableColumn[];
  data: TableData[];
  className: string;
  renderCell: (
    rowIndex: number,
    rowData: TableData,
    column: TableColumn,
    columnIndex: number, 
  ) => React.ReactNode;
  maxHeight?: number;
  renderColumn: (column: TableColumn, colnumber:number) => React.ReactNode;
}

const TableComponent: React.FC<TableComponentProps> = ({
  columns,
  data,
  className,
  maxHeight,
  renderCell,
  renderColumn,
}) => {


  useEffect(() => {

  }, [data]);


  return (
    <div className={className}>
      <div className="flex overflow-clip justify-start">
        {columns.map((column, columnIndex) => (
          <div
            style={{ width: '100%', minWidth: column.width }}
          >
            {renderColumn(column, columnIndex)}
          </div>
        ))}
      </div>
      <div style={{height:maxHeight, overflowX:'clip', overflowY:'auto'}} >
    
        {data.map((rowData, rowIndex) => (
          <div
            className={`flex justify-start`}
          >
            {columns.map((column,columnIndex) => (
              <div
                style={{ width: '100%', minWidth: column.width }}
              >
                {renderCell(rowIndex, rowData, column,columnIndex)}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TableComponent;
