// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagination {
    display: flex;
    justify-content: center;
    margin-top: 15px;
  }
 
  ul {
    list-style: none;
    padding: 0;
  }
 
  ul.pagination li {
    display: inline-block;
    width: 30px;
    height: 30px;
 
    
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
  }
 
  ul.pagination li:first-child{
    border-radius: 0px 0 0 0px;
  }
 
  ul.pagination li:last-child{
    border-radius: 0 0px 0px 0;
  }
 
  ul.pagination li a {
    text-decoration: none;
    color: #202020;
    font-size: 1rem;
  }
 
  ul.pagination li.active a {
    color: white;
  }
 
  ul.pagination li.active {
    background-color: #000000;
    border-radius: 5px;
  
  }
 
  ul.pagination li a:hover,
  ul.pagination li a.active {
    color: rgb(206, 206, 206);
  }
 
  .page-selection {
    width: 48px;
    height: 30px;
    color: #337ab7;
  }`, "",{"version":3,"sources":["webpack://./src/components/booking_hospital/Paging.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,gBAAgB;EAClB;;EAEA;IACE,gBAAgB;IAChB,UAAU;EACZ;;EAEA;IACE,qBAAqB;IACrB,WAAW;IACX,YAAY;;;IAGZ,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,eAAe;EACjB;;EAEA;IACE,0BAA0B;EAC5B;;EAEA;IACE,0BAA0B;EAC5B;;EAEA;IACE,qBAAqB;IACrB,cAAc;IACd,eAAe;EACjB;;EAEA;IACE,YAAY;EACd;;EAEA;IACE,yBAAyB;IACzB,kBAAkB;;EAEpB;;EAEA;;IAEE,yBAAyB;EAC3B;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,cAAc;EAChB","sourcesContent":[".pagination {\r\n    display: flex;\r\n    justify-content: center;\r\n    margin-top: 15px;\r\n  }\r\n \r\n  ul {\r\n    list-style: none;\r\n    padding: 0;\r\n  }\r\n \r\n  ul.pagination li {\r\n    display: inline-block;\r\n    width: 30px;\r\n    height: 30px;\r\n \r\n    \r\n    display: flex;\r\n    justify-content: center;\r\n    align-items: center;\r\n    font-size: 1rem;\r\n  }\r\n \r\n  ul.pagination li:first-child{\r\n    border-radius: 0px 0 0 0px;\r\n  }\r\n \r\n  ul.pagination li:last-child{\r\n    border-radius: 0 0px 0px 0;\r\n  }\r\n \r\n  ul.pagination li a {\r\n    text-decoration: none;\r\n    color: #202020;\r\n    font-size: 1rem;\r\n  }\r\n \r\n  ul.pagination li.active a {\r\n    color: white;\r\n  }\r\n \r\n  ul.pagination li.active {\r\n    background-color: #000000;\r\n    border-radius: 5px;\r\n  \r\n  }\r\n \r\n  ul.pagination li a:hover,\r\n  ul.pagination li a.active {\r\n    color: rgb(206, 206, 206);\r\n  }\r\n \r\n  .page-selection {\r\n    width: 48px;\r\n    height: 30px;\r\n    color: #337ab7;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
