import React, { useEffect, ReactNode } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

interface CheckQueryParameterProps {
  children: ReactNode;
  redirectUrl: string;
  requiredParams: string[];
}

const CheckQueryParameter: React.FC<CheckQueryParameterProps> = ({ children, redirectUrl, requiredParams }) => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    // Check if all required parameters are present
    const allParamsPresent = requiredParams.every(param => searchParams.has(param));

    if (!allParamsPresent) {
      navigate(redirectUrl);
    }
  }, [location, navigate, redirectUrl, requiredParams]);

  return (
    <>
      {children}
    </>
  );
};

export default CheckQueryParameter;
