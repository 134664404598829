import React, { useEffect } from 'react';

type RedirectProps = {
  url: string;
};

const AutoRedirect: React.FC<RedirectProps> = ({ url }) => {
  useEffect(() => {
    window.location.href = url;
  }, [url]);

  return null;
};

export default AutoRedirect;
