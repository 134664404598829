import React, { useEffect, useState } from 'react';
import TabComponent, { Tab } from '../lib/modal/TabComponent';
import TableComponent, { TableColumn, TableData } from '../lib/modal/TableComponent';
import { ko } from 'date-fns/locale';
import DatePicker from 'react-datepicker';
import Pagination from 'react-js-pagination';
import './Paging.css';
import useQueryParamsRESTAPI from '../lib/hook/useQueryParamsRESTAPI';
import useQueryParams from '../lib/hook/useQueryParams';
import { formatDate, formatDateTime, formatTime } from '../lib/common/Auxiliary';
import Dropdown from '../lib/common/DropDown';
import useRESTAPI from '../lib/hook/RESTAPI';
import { useAuth } from '../lib/provider/AuthContext';
import { useNavigate, useParams } from 'react-router-dom';
import { useModal } from '../lib/modal/ModalContext';

const Admin: React.FC = () => {
    const {
        addItem: addBookableItem,
        fetchItems: fetchBookableItems,
        items: bookable,
        removeItem: removeBookableItem
    } = useRESTAPI('/api/bookable')
    const { adminid } = useAuth()
    const navigate = useNavigate();
    const { store } = useParams<{ store: string }>();
    const { items: bookingItems, pagination, updateItems } = useQueryParamsRESTAPI('/api/booking');
    const { queryParams, setQueryParams } = useQueryParams();
    const [selectedDate, setSelectedDate] = useState<Date>(new Date());
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 10;
    type Weekday = 'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday' | 'Saturday' /* | 'Sunday'; */


    const [weekData, setWeekData] = useState<TableData[]>([
        { day: 'Monday', startTime: '09:00', endTime: '17:00' },
        { day: 'Tuesday', startTime: '09:00', endTime: '17:00' },
        { day: 'Wednesday', startTime: '09:00', endTime: '17:00' },
        { day: 'Thursday', startTime: '09:00', endTime: '17:00' },
        { day: 'Friday', startTime: '09:00', endTime: '17:00' },
        { day: 'Saturday', startTime: '10:00', endTime: '14:00' },
        /*         { day: 'Sunday', startTime: '10:00', endTime: '14:00' }, */
    ]);

    const { items: campaigns, removeItem:removeCampaigns, editItem: editCampaigns, addItem: addCampaigns, fetchItems: fetchCampaigns } = useRESTAPI('/api/campaign');



    useEffect(() => {

    }, [weekData]);

    useEffect(() => {
        console.log(campaigns)
    }, [campaigns]);

    useEffect(() => {
        if (adminid === undefined)
            return;

        const startOfDay = selectedDate;
        startOfDay.setHours(0, 0, 0, 0);
        const endOfDay = selectedDate;
        endOfDay.setHours(23, 59, 59, 999);

        setQueryParams({
            ...queryParams,
            hospital: adminid,
            datetime_min: startOfDay.toISOString(),
            datetime_max: endOfDay.toISOString(),
            page: 1
        });

        const fetchAndSetData = async () => {

            const data: any = await fetchBookableItems({ hospital: adminid, pageSize: 14, page: 1 });
            if (data) {
                const formattedData = weekData.map((defaultItem) => {
                    const dayData = data.filter((item: any) => item.weekDay === defaultItem.day);
                    const startTime = dayData.find((item: any) => item.type === 'startTime')?.time.slice(0, 5) || defaultItem.startTime;
                    const endTime = dayData.find((item: any) => item.type === 'endTime')?.time.slice(0, 5) || defaultItem.endTime;

                    return {
                        day: defaultItem.day,
                        startTime,
                        endTime,
                    };
                });

                setWeekData(formattedData);
            }
        };

        fetchAndSetData();
        console.log(adminid)
        fetchCampaigns({ hospital: adminid });
    }, [adminid]);

    useEffect(() => {
        if (queryParams?.datetime_min) {
            updateItems();

        }


    }, [queryParams]);


    const tabs: Tab[] = [
        { label: '예약자 명단', key: 'booking-list' },
        { label: '예약 가능 시간/요일', key: 'settings' },
        { label: '캠페인 관리', key: 'campaign' },
    ];

    const renderCustomTab = (tab: Tab, isActive: boolean) => {
        return (
            <div
                className={`${isActive ? 'text-blue-500 bg-blue-100' : ' text-gray-800'} rounded-lg cursor-pointer py-2 px-4`}
            >
                {tab.label}
            </div>
        );
    };



    const handleDateChange = (date: Date | null) => {
        if (!date)
            date = new Date();
        ;
        if (date) {
            const startOfDay = new Date(date);
            startOfDay.setHours(0, 0, 0, 0);
            const endOfDay = new Date(date);
            endOfDay.setHours(23, 59, 59, 999);
            setQueryParams({
                ...queryParams,
                datetime_min: startOfDay.toISOString(),
                datetime_max: endOfDay.toISOString(),
                page: 1
            });


        }
    };

    const bookCols: TableColumn[] = [
        { key: 'name', label: '예약자', width: 120 },
        { key: 'phoneNumber', label: '전화번호', width: 170 },
        { key: 'datetime', label: '일시', width: 130 },

    ];

    const handleCellChange = (rowIndex: number, columnKey: string, newValue: string) => {
        const updatedCampaign = {
            ...campaigns[rowIndex], 
            [columnKey]: newValue,  
        };


        editCampaigns(rowIndex, updatedCampaign);
    };

    const renderCCell = (rowIndex: number, rowData: TableData, column: TableColumn) => {
        const value = rowData[column.key];
        if (column.key === '') {
 
            return (
                <div className='border-[1px] flex justify-center item-center h-[39px]'>
                <button
                    className=" text-red-500 px-2 py-1 rounded-lg"
                    onClick={() => handleDeleteCampaign(rowIndex)}
                >
                    삭제
                </button></div>
            );
        }
        const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {

            handleCellChange(rowIndex, column.key, e.target.value);
        };

        return (
            <input
                className={`border-[1px] flex items-center justify-start w-full h-[39px] pl-3`}
                defaultValue={value}
                onChange={handleChange}
            />
        );
    };
    /* 
    const renderCCell = (rowIndex: number, rowData: TableData, column: TableColumn, columnIndex: number) => {
        return (
            <input 
                className={`border-t-[1px] flex items-center justify-start w-full  h-[39px] pl-3`}
            >
                {rowData[column.key]}
            </input>
        );
    }; */
    const renderWeekCell = (rowIndex: number, rowData: TableData, column: TableColumn, columnIndex: number) => {
        const day = rowData['day'] as Weekday;

        if (column.key === 'day') {
            return <div className={`border-t-[1px] flex items-center justify-start w-full  h-[39px] pl-3`} >{day}</div>;
        }

        const timeSlots = generateTimeSlots();
        const options = timeSlots.map((slot, index) => {
            const formattedTime = `${slot.getHours().toString().padStart(2, '0')}:${slot.getMinutes().toString().padStart(2, '0')}`;
            return {
                key: formattedTime,
                label: formattedTime
            };
        });

        const selectedOption = rowData[column.key];

        return (
            <div className={`border-t-[1px] flex items-center justify-start w-full  h-[39px] pl-3 `} >
                <Dropdown
                    key={column.key + rowData.day}
                    options={options}
                    selectedOptionKey={selectedOption}
                    buttonClassName="px-4   w-full h-[25x] flex justify-between items-center rounded-lg  "
                    dropdownClassName="flex flex-col border-[1px] border-[#D8DDE3] max-h-[200px] overflow-y-scroll w-[110px]  flex justify-between items-start rounded-lg bg-white "
                    itemClassName="px-4  w-full h-[30px]"
                    itemSelectedClassName="px-4 h-[30px] w-full  "
                    itemHoverClassName="px-4 h-[30px] w-full "
                    onOptionChange={(key) => handleTimeChange(day, column.key as 'startTime' | 'endTime', key)}
                />
            </div>
        )
    };



    const renderCell = (
        rowindex: number,
        rowData: TableData,
        column: TableColumn,
    ) => {
        return (
            <div
                className={`border-t-[1px] flex items-center justify-start w-full  h-[39px] pl-3`}
            >
                {rowData[column.key]}
            </div>
        );

    };

    const renderCol = (column: TableColumn) => {

        const needLine = column.key !== bookCols[0].key && column.key !== weekColumns[0].key && column.key !== cCol[0].key;

        return (
            <div
                className={`bg-gray-200  border-b-[1px] flex items-center justify-start w-full  h-[48px]`}
            >
                {needLine ? <div className="bg-gray-400 h-2/4 w-[1px]"></div> : <></>}

                <div className="flex w-full justify-center">{column.label}</div>
            </div>
        );
    };

    const weekColumns: TableColumn[] = [
        { key: 'day', label: '요일', width: 100 },
        { key: 'startTime', label: '예약 시작', width: 150 },
        { key: 'endTime', label: '예약 종료', width: 150 },
    ];


    function generateTimeSlots(): Date[] {
        const timeSlots: Date[] = [];
        const startDate = new Date();
        startDate.setHours(0, 0, 0, 0);

        for (let i = 0; i < 48; i++) {
            const slot = new Date(startDate);
            slot.setMinutes(startDate.getMinutes() + i * 30);
            timeSlots.push(slot);
        }

        return timeSlots;
    }
    /* 
        const handleTimeChange = (day: Weekday, type: 'startTime' | 'endTime', time: string) => {
            setWeekTimes(prevWeekTimes => ({
                ...prevWeekTimes,
                [day]: {
                    ...prevWeekTimes[day],
                    [type]: time,
                },
            })); 
        };*/

    /*     const handleTimeChange = (day: Weekday, type: 'startTime' | 'endTime', time: string) => {
            setWeekTimes(prevWeekTimes => {
                const newWeekTimes = {
                    ...prevWeekTimes,
                    [day]: {
                        ...prevWeekTimes[day],
                        [type]: time,
                    },
                };
                return newWeekTimes;
            });
        };
     */
    /* const handleTimeChange = (day: Weekday, type: 'startTime' | 'endTime', time: string) => {

        setWeekData(prevWeekData =>
            prevWeekData.map((item) =>
                item.day === day ? { ...item, [type]: time } : item
            )
        );
    }; */
    const handleTimeChange = (day: Weekday, type: 'startTime' | 'endTime', time: string) => {
        setWeekData(prevWeekData =>
            prevWeekData.map((item) => {
                if (item.day === day) {
                    let newStartTime = item.startTime;
                    let newEndTime = item.endTime;

                    if (type === 'startTime') {
                        newStartTime = time;
                    } else if (type === 'endTime') {
                        newEndTime = time;
                    }


                    if (newStartTime > newEndTime) {

                        alert('시작 시간이 종료 시간보다 나중입니다. 시간이 자동으로 조정되었습니다.');

                        [newStartTime, newEndTime] = [newEndTime, newStartTime];
                    }

                    return { ...item, startTime: newStartTime, endTime: newEndTime };
                }
                return item;
            })
        );
    };



    const RenderTileContent = (date: Date) => {
        const today = new Date();
        const isPastDate = date < today && date.toDateString() !== today.toDateString();

        return (
            <div className={`font-light ${isPastDate ? 'text-gray-400' : 'text-black-400'}`}>
                {date.getDate()}
            </div>
        );
    };

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
        setQueryParams({ ...queryParams, page: page, limit: pageSize });

    };

    const bookingTab = () => {
        return (
            <div className='p-4 w-full h-full flex justify-center items-start'>
                <div className='p-8 flex w-[500px] min-w-[500px] h-full flex-col justify-center items-center border-[1px] bg-white rounded-xl'>
                    <div className='flex w-full flex-col justify-center items-center'>
                        <div className='h-[60px] w-full  flex justify-start  text-gray-700 items-center font-extrabold text-2xl border-b-[1px] '>예약자 명단</div>
                        <div className='h-20' />
                        <div className='flex flex-col justify-center items-center w-full'>
                            <div className='h-[30px] w-full  flex justify-start  text-gray-700 items-center font-extrabold text-xl'>날짜 선택</div>
                            <div className='h-5' />
                            <DatePicker
                                locale={ko}
                                inline
                                dateFormat="yyyy년 MM월"
                                onSelect={handleDateChange}
                                renderDayContents={(day, date) => {
                                    return RenderTileContent(date ? date : new Date());
                                }}
                            />

                            <div className='h-[30px] w-full  flex justify-start  text-gray-700 items-center font-extrabold text-xl'>예약자 리스트</div>
                            <div className='h-5' />
                            {bookingItems.length === 0 && <div className='flex justify-center items-center bg-gray-100 rounded-lg w-[433px] h-[470px]'>예약자가 없습니다</div>}

                            {bookingItems.length > 0 && <><TableComponent
                                columns={bookCols}
                                data={bookingItems.map(item => {
                                    return {
                                        name: item.user?.name || '',
                                        phoneNumber: item.user?.phone_number || '',
                                        datetime: formatTime(new Date(item.datetime))
                                    };
                                })}
                                className="border-[1px] w-full overflow-clip rounded-lg"
                                renderCell={renderCell}
                                renderColumn={renderCol}
                                maxHeight={390}
                            /><Pagination
                                    totalItemsCount={pagination.totalCount}
                                    itemsCountPerPage={pageSize}
                                    activePage={currentPage}
                                    onChange={handlePageChange}
                                /></>}

                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const bookableTab = () => {

        return (
            <div className='p-4 w-full h-full flex justify-center items-start'>
                <div className='p-8 flex w-[500px] min-w-[500px] h-full flex-col justify-center items-center border-[1px] bg-white rounded-xl'>
                    <div className='flex w-full flex-col justify-center items-center'>
                        <div className='h-[60px] w-full  flex justify-start  text-gray-700 items-center font-extrabold text-2xl border-b-[1px] '>예약 가능 시간/요일</div>
                        <div className='h-20' />
                        <div className='h-[30px] w-full  flex justify-start  text-gray-700 items-center font-extrabold text-xl'>예약 가능 시간 설정</div>
                        <div className='h-10' />
                        <TableComponent
                            columns={weekColumns}
                            data={weekData}
                            className="border-[1px] w-full overflow-clip rounded-lg"
                            renderCell={renderWeekCell}
                            renderColumn={renderCol}
                            maxHeight={300}
                        /></div>
                    <div className='h-10' />
                    <div onClick={saveBookable} className='text-white w-full flex justify-center bg-black rounded-lg cursor-pointer py-2 px-4' >저장하기</div>
                </div>


            </div>)

    }

    const cCol: TableColumn[] = [
        { key: 'lead_form_id', label: 'Meta 양식 ID', width: 150 },
        { key: 'media', label: '대상 미디어', width: 50 },
        { key: 'category', label: '분류', width: 50 },
        { key: 'campaign_name', label: '캠페인명', width: 50 },
        { key: 'business', label: '업체명', width: 50 },
        { key: '', label: '삭제', width: 50 },
    ];

    const { showModal, hideModal } = useModal();
    const handleDeleteCampaign = async (rowIndex: number) => {
        const campaignToDelete = campaigns[rowIndex]; 
       
        const {result,error} = await removeCampaigns(campaignToDelete.id); 
        if(error) {
            alert('사용 중인 켐페인 입니다.');
        }

        fetchCampaigns({ hospital: adminid }); 
   
    };

    
    const addNewCampaign = () => {
     
        const campaignForm = (
            <div className="p-6 bg-white rounded-lg shadow-lg w-[400px]">
                <h2 className="text-2xl font-bold mb-4">새 캠페인 추가</h2>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <label className="block text-sm font-medium">캠페인명</label>
                        <input type="text" name="campaign_name" className="w-full border rounded-lg p-2" required />
                    </div>
                    <div className="mb-4">
                        <label className="block text-sm font-medium">Meta 양식 ID</label>
                        <input type="text" name="lead_form_id" className="w-full border rounded-lg p-2" required />
                    </div>
                    <div className="mb-4">
                        <label className="block text-sm font-medium">대상 미디어</label>
                        <input type="text" name="media" className="w-full border rounded-lg p-2" required />
                    </div>
                    <div className="mb-4">
                        <label className="block text-sm font-medium">분류</label>
                        <input type="text" name="category" className="w-full border rounded-lg p-2" required />
                    </div>
                    <div className="mb-4">
                        <label className="block text-sm font-medium">병원 명</label>
                        <input type="text" name="business" className="w-full border rounded-lg p-2" required />
                    </div>
                    <div className="flex justify-end space-x-2">
                        <button type="button" onClick={hideModal} className="bg-gray-300 px-4 py-2 rounded-lg">취소</button>
                        <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded-lg">저장하기</button>
                    </div>
                </form>
            </div>
        );

     
        showModal(campaignForm);
    };
    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        const formData = new FormData(event.target as HTMLFormElement);
   
        const newCampaign = {
            hospital: adminid,
            campaign_name: formData.get('campaign_name'),
            lead_form_id: formData.get('lead_form_id'),
            media: formData.get('media'),
            category: formData.get('category'),
            business: formData.get('business'),
        };

        try {
            await addCampaigns(newCampaign);
            alert('캠페인이 성공적으로 추가되었습니다.');
            hideModal(); 

        } catch (error) {
            alert('캠페인 추가 중 오류가 발생했습니다.');
        }
    };
    const cTab = () => {

        return (
            <div className='p-4 w-full h-full flex justify-center items-start'>
                <div className='p-8 flex w-[800px] min-w-[800px] h-full flex-col justify-center items-center border-[1px] bg-white rounded-xl'>
                    <div className='flex w-full flex-col justify-center items-center'>
                        <div className='h-[60px] w-full  flex justify-start  text-gray-700 items-center font-extrabold text-2xl border-b-[1px] '>켐페인 관리</div>
                        <div className='h-20' />
                        <div className='h-[30px] w-full  flex justify-start  text-gray-700 items-center font-extrabold text-xl'>켐페인 정의 설정</div>
                        <div className='h-10' />
                        <TableComponent
                            columns={cCol}
                            data={campaigns}
                            className="border-[1px] w-full overflow-y-scroll rounded-lg"
                            renderCell={renderCCell}
                            renderColumn={renderCol}
                            maxHeight={300}
                        />
                    </div>

                    <div className='h-10' />
                    <div onClick={addNewCampaign} className='text-white w-full flex justify-center bg-black rounded-lg cursor-pointer py-2 px-4' >추가</div>
                    <div className='h-2' />
                    <div onClick={saveBookable} className='text-white w-full flex justify-center bg-black rounded-lg cursor-pointer py-2 px-4' >저장하기</div>
                </div>


            </div>)

    }



    const saveBookable = async () => {
        try {
            const data: any = await fetchBookableItems({ hospital: adminid, pageSize: 14, page: 1 });

            const removalPromises = data.map((item: any) => removeBookableItem(item.id));
            await Promise.all(removalPromises);

            for (const { day, startTime, endTime } of weekData) {
                await addBookableItem({
                    time: startTime,
                    hospital: adminid,
                    week_day: day,
                    type: 'startTime',
                });

                await addBookableItem({
                    time: endTime,
                    hospital: adminid,
                    week_day: day,
                    type: 'endTime',
                });
            }

            alert('저장 완료');
        } catch (error) {
            alert('저장 중 오류가 발생했습니다.');
        }
    };


    return (
        <div className='bg-gray-100'>
            <div className='h-[80px] w-full border-b-[1px]  border-gray-300 flex justify-start pl-8 text-gray-700 items-center font-extrabold text-4xl'>관리자 페이지</div>
            <div className='flex'>
                <div className='flex p-4 flex-col h-screen'>

                    <TabComponent tabs={tabs} itemClassName='' mainClassName='w-[300px] ' activeClassName='' renderTab={renderCustomTab} />
                </div>
                <div className='flex w-full h-full flex-col justify-center items-center'>
                    {queryParams['tab'] === 'booking-list' ? bookingTab() : undefined}
                    {queryParams['tab'] === 'settings' ? bookableTab() : undefined}
                    {queryParams['tab'] === 'campaign' ? cTab() : undefined}
                </div>
            </div>
        </div>
    );
}

export default Admin;
