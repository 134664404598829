import React, { ReactNode, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../provider/AuthContext';

interface RequireAuthProps {
  children: ReactNode;
  redirectTo?: string; 
}

const RequireAuth = ({ children, redirectTo = "/login" }: RequireAuthProps) => { 
  const [isSignedIn, setIsSignedIn] = useState<boolean | undefined>(undefined);
  const { userid } = useAuth();

  useEffect(() =>  {
    const checkSession = async () => {
      try {
        const response = await fetch('/api/auth/readSession');
  
        if (response.ok) {
          const data = await response.json();
          
          setIsSignedIn(true);
        } else {
          setIsSignedIn(false);
        }
      } catch (error) {
        setIsSignedIn(false);
      }
    };

    if (!userid) {
      checkSession();
    } else {
      setIsSignedIn(true);
    }
  }, [userid]);
  
  if (isSignedIn === false) {
    window.location.href = redirectTo
    return <></>;
  } else if (isSignedIn === true) {
    return <>{children}</>;
  }
  
  return <></>;
};

export default RequireAuth;
