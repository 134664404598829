import React, { useEffect, useState } from 'react';
import ModalBase from '../lib/common/ModalBase';
import { Link, useNavigate } from 'react-router-dom';
import Checkbox from '../lib/common/Checkbox';
import { useAuth } from '../lib/provider/AuthContext';
 


export const LogIn = (): JSX.Element => {
  const [identity, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [autoLogin, setAutoLogin] = useState<boolean>(false);
  const [once, setOnce] = useState<boolean>(false);

  const { adminSignIn, storedAdminIdentity, storedAdminPassword, loginAttempts } =
    useAuth();


  useEffect(() => {

    const _ = async (storedAdminIdentity: string, storedAdminPassword: string) => {
      try {
        await adminSignIn(storedAdminIdentity, storedAdminPassword, autoLogin);
        navi('/customer-value-status');
      } catch (error) { }
    };

    if (!once && storedAdminIdentity && storedAdminPassword) {
      _(storedAdminIdentity, storedAdminPassword);
    }
    setOnce(true);
  }, []);


  const navi = useNavigate();
  const OnLogin = async () => {

    try {
      await adminSignIn(identity, password, false);
      navi('/admin')
    } catch (error) {

    }
  };

  return (

    <div className='bg-white h-screen flex justify-center items-center'>
      <div className='flex flex-col  justify-between bg-gray-100 border-gray-200 border-[1px] rounded-3xl p-10 w-[500px] h-96'>
        <div>
          <div className='text-3xl font-bold' >관리자 로그인</div>
          
        </div>
        <div>
            <div>아이디</div>
            <div className='h-1'></div>
            <input
              placeholder="아이디를 입력해 주세요."
              className="pl-5 rounded-lg w-full border-[1px] px-2 py-2 border-gray-400 text-black"
              value={identity}
              onChange={(e) => setUsername(e.target.value)}
            />
            <div className='h-4'></div>
            <div>비밀번호</div>
            <div className='h-1'></div>
            <input
              type="password"
              placeholder="비밀번호를 입력해 주세요."
              className="pl-5 rounded-lg w-full border-[1px] px-2 py-2 border-gray-400 text-black"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
        <button className='bg-black rounded-lg py-2 px-2 text-white' onClick={OnLogin} >로그인하기</button>
      </div>
    </div>
  );
};

export default LogIn;
