import React, { ReactNode, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../provider/AuthContext';

interface RequireAuthProps {
  children: ReactNode;
  redirectUrl?: string;
}

const RequireAdminAuth = ({ children, redirectUrl = "/adminlogin" }: RequireAuthProps) => {
  const [isAdminSignIn, setIsSignedIn] = useState<boolean | undefined>(undefined);
  const { adminid } = useAuth();

  const fetchData = async () => {
    try {
      const response = await fetch('/api/auth/readAdminSession');
   
      if (response.ok) {
        const data = await response.json();
        setIsSignedIn(true);
      } else {
        setIsSignedIn(false);
      }
    } catch (error) {
      setIsSignedIn(false);
    }
  };

  useEffect(() => {
    if (!adminid) {
      fetchData();
    } else {
      setIsSignedIn(true);
    }
  }, [adminid]);

  if (isAdminSignIn === false) {
    return <Navigate to={redirectUrl} replace />;
  } else if (isAdminSignIn === true) {
    return <>{children}</>;
  }

  return null;
};

export default RequireAdminAuth;
