import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

type QueryParams = {
  [key: string]: string | number | boolean | undefined;
};

const useQueryParams = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [queryParams, setQueryParamsState] = useState<QueryParams>({});

  useEffect(() => {

    const searchParams = new URLSearchParams(location.search);
    const params: QueryParams = {};
    searchParams.forEach((value, key) => {
      params[key] = value;
    });
    setQueryParamsState(params);
  }, [location.search]);

  
const setQueryParams = (params: QueryParams) => {


  const updatedParams = new URLSearchParams();

  Object.keys(params).forEach((key) => {
    const value = params[key];
    updatedParams.set(key, String(value));
  });


  const queryString = Array.from(updatedParams.entries())
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&');

  navigate(`?${queryString}`);
};

  return { queryParams, setQueryParams };
};

export default useQueryParams;
