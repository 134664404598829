import React from 'react';

interface Props {
    items: string[];
    className: string;
    onItemClick: (index: number, item: string) => void;
    renderItem: (item: string, index: number) => React.ReactNode;
}

const ToggleText: React.FC<Props> = ({ items, className, onItemClick, renderItem }) => {
    return (
        <div className={className}>
            {items.map((item, index) => (
                <div onClick={() => onItemClick(index, item)} key={index}>
                    {renderItem(item, index)}
                </div>
            ))}
        </div>
    );
};

export default ToggleText;
