import { useState, useEffect, useCallback } from 'react';
import { json } from 'stream/consumers';

const getAllItems = async (endpoint: string) => {
  const response = await fetch(endpoint);
  if (!response.ok) {
    throw new Error('Failed to fetch items');
  }
  const data = await response.json();
  return data;
};

const getOneItem = async (endpoint: string, id: number) => {
  const response = await fetch(`${endpoint}/${id}`);
  if (!response.ok) {
    throw new Error(`Failed to fetch item with id ${id}`);
  }
  const data = await response.json();
  return data;
};

const createItem = async (endpoint: string, data: any) => {
  const response = await fetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  if (!response.ok) {
    console.log(await response.text())
    throw new Error('Failed to create item');
  }
  const newItem = await response.json();
  return newItem;
};

const updateItem = async (endpoint: string, id: number, data: any) => {
  data.id = id;
  const response = await fetch(endpoint, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  if (!response.ok) {
    throw new Error(`Failed to update item with id ${id}`);
  }
  const updatedItem = await response.json();
  return updatedItem;
};

const deleteItem = async (endpoint: string, id: number) => {

  const response = await fetch(`${endpoint}/${id}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json'
    },
  });

  if (!response.ok) {
    throw new Error(`Failed to delete item with id ${id}`);
  }
};

export function buildQueryParams(params: { [key: string]: any }): string {
  const queryParams = new URLSearchParams();
  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      queryParams.append(key, params[key]);
    }
  }
  return queryParams.toString();
}

const useRESTAPI = (endpoint: string) => {
  const [items, setItems] = useState<any[]>([]);
  const [hasFetchedSuccessfully, setHasFetchedSuccessfully] = useState(false);
  const [pagination, setPagination] = useState({ pageSize: 10, totalPage: 1, page: 1, totalCount: 0, offset: 0 });



  useEffect(() => {

    
  }, [items]);

  const fetchItems = useCallback(async (query: { [key: string]: any }) => {
    try {
      const queryParams = buildQueryParams(query);
  
      const data = await getAllItems(endpoint +'?'+ queryParams);
      setItems(prevItems => data.data);
      setPagination({
        pageSize: data.pageSize,
        totalPage: data.totalPage,
        page: data.page,
        totalCount: data.totalCount,
        offset: data.offset,
      });

      setHasFetchedSuccessfully(true);
   
      return data.data;
    } catch (error) {
      console.error(error);
    }
  }, [endpoint]);

  const fetchItem = useCallback(async (id: number) => {
    try {
      const item = await getOneItem(endpoint, id);
      setHasFetchedSuccessfully(true);
      return item;
    } catch (error) {
      console.error(error);
      return null;
    }
  }, [endpoint]);

  const addItem = useCallback(async (item: Omit<any, 'id'>) => {
    try {
      const newItem = await createItem(endpoint, item);
      setItems(prevItems => [...prevItems, newItem]);
      setHasFetchedSuccessfully(true);

      const date = newItem;
      return { date,undefined };
    } catch (error) {
      console.error(error);
      return {undefined,error };
    }
  }, [endpoint]);

  const editItem = useCallback(async (id: number, item: Omit<any, 'id'>) => {
    try {
      const updatedItem = await updateItem(endpoint, id, item);
      setItems(prevItems => prevItems.map((i) => (i.id === id ? updatedItem : i)));
      setHasFetchedSuccessfully(true);
      return updatedItem;
    } catch (error) {
      console.error(error);
      return null;
    }
  }, [endpoint]);

  const removeItem = useCallback(async (id: number) => {
    try {
      await deleteItem(endpoint, id);
      setItems(prevItems => prevItems.filter((i) => i.id !== id));
      setHasFetchedSuccessfully(true);
      const result = true;
      return { result,undefined };
    } catch (error) {
      console.error(`Error removing item with id: ${id}`);
      
      if (error instanceof Error) {
        console.error(error.stack || error.message);
      } else {
        console.error('An unknown error occurred:', error);
      }
      const result = false;
      return { result,error };
    }
  }, [endpoint]);
/* 
  useEffect(() => {
    fetchItems({});
  }, [fetchItems]); */

  return { items, fetchItems, fetchItem, addItem, editItem, removeItem, hasFetchedSuccessfully, pagination };
};

export default useRESTAPI;
