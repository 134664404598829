import React from 'react';

interface ModalProps {
  onClose: () => void;
  title: string;
  text: string;
}

const Modal: React.FC<ModalProps> = ({ onClose, title, text }) => {


  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="fixed inset-0 bg-gray-800 bg-opacity-75"></div>
      <div className="bg-white rounded-lg shadow-lg p-6 z-10 w-[300px]">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-semibold">{title}</h2>
          <button
            className="text-gray-500 hover:text-gray-700"
            onClick={onClose}
          >
            &times;
          </button>
        </div>
        <div className="mb-4">
          {text}
        </div>
        <div className="flex justify-end">
          <button
            className="bg-[#90BDDE] text-white px-4 py-2 rounded "
            onClick={onClose}
          >
            확인
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;