import React, { useState } from 'react';
import useRESTAPI from '../lib/hook/RESTAPI';
import { useLocation, useNavigate } from 'react-router-dom';

interface UserData {
  name: string;
  phoneNumber: string;
}

const NamePhone: React.FC = () => {
  const [userData, setUserData] = useState<UserData>({ name: '', phoneNumber: '' });
  const [errors, setErrors] = useState<{ name?: string; phoneNumber?: string }>({});
  const navigate = useNavigate();
  const location = useLocation();

  function convertToInternationalFormat(phone: string): string {

    if (phone.length === 11) {
      // 11자리 전화번호 형식: 01012345678
      return `+82 ${phone.slice(1, 3)}-${phone.slice(3, 7)}-${phone.slice(7)}`;
  } else if (phone.length === 10) {
      // 10자리 전화번호 형식: 0101234567
      return `+82 ${phone.slice(1, 3)}-${phone.slice(3, 6)}-${phone.slice(6)}`;
  } else {
      // 잘못된 형식의 전화번호에 대한 처리
      return "Invalid phone number";
  }
}
  

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUserData({
      ...userData,
      [name]: value,
    });
  };

  const validate = (): boolean => {
    const newErrors: { name?: string; phoneNumber?: string } = {};
    if (!userData.name) newErrors.name = '이름을 입력해주세요.';

    if (!userData.phoneNumber) {
      newErrors.phoneNumber = '전화번호를 입력해주세요.';
    } else if (!/^\d{10,11}$/.test(userData.phoneNumber)) {
      newErrors.phoneNumber = '유효한 전화번호를 입력해주세요.';
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleClick = async () => {
    if (validate()) {
      const searchParams = new URLSearchParams(location.search);
      const queryString = searchParams.toString();

      const response = await fetch(`/api/auth/login/phoneNumber?${queryString}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: userData.name,
          phoneNumber: convertToInternationalFormat(userData.phoneNumber),
        }),
      });

      if (response.ok) {
        navigate('/booking');
      } else {
        console.error('Failed to submit data');
      }
    }
  };

  return (
    <div className='flex justify-center items-center w-screen h-screen bg-gray-100'>
      <div className='overflow-clip border-[1px]  rounded-xl bg-white w-[375px]'>

        <div className="max-w-md mx-auto p-6 ">
          <div style={{ backgroundImage: 'url(/logo.png)', backgroundSize: 'contain', backgroundPosition: 'start', backgroundRepeat: 'no-repeat', height: '60px', width: '140px' }} />
          <div className='h-1'></div>
          <div className='text-sm'>연락처 정보를 남겨주시면 상담원이 연락드립니다.</div>
          <div>

            <div className='h-8'></div>
            <div className='text-gray-500 text-xs'>
              이름
            </div>
            <div className='h-2'></div>
            <input
              type="text"
              name="name"
              placeholder='성함을 입력해 주십시오.'
              value={userData.name}
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
            {errors.name && <p className="text-red-500 text-xs mt-1">{errors.name}</p>}
          </div>
          <div className='h-4'></div>
          <div>
            <div className='text-gray-500 text-xs'>
              전화번호
            </div>
            <div className='h-2'></div>
            <input
              type="text"
              name="phoneNumber"
              placeholder='전화번호를 입력해 주십시오.'
              value={userData.phoneNumber}
              onChange={handleChange}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
            {errors.phoneNumber && <p className="text-red-500 text-xs mt-1">{errors.phoneNumber}</p>}
          </div>
          <div className='h-4'></div>
          <div className='text-gray-500 text-xs'>
            <div className='underline'>개인정보처리방침 </div>
            <div className='h-1'></div>
            <div>
              제출을 클릭하면 회원님의 정보를 전송하며 해당 페이지가 개인정보처리방침에 따라 이 정보를 사용하는 것에 동의하게 됩니다.
            </div>
          </div>
          <div className='h-20'></div>
          <button
            onClick={handleClick}
            className="w-full bg-[#ad7237] text-white py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium hover:bg-[#b17e4a] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:bg-[#784b1e]"
          >
            제출
          </button>
        </div>
      </div>
    </div >
  );
};

export default NamePhone;
