import React, { useEffect } from 'react';
import useQueryParams from '../hook/useQueryParams';

export interface Tab {
  label: string;
  key: string;
}

interface Props {
  tabs: Tab[];
  mainClassName?: string;
  itemClassName?: string;
  activeClassName?: string;
  renderTab?: (
    tab: Tab,
    isActive: boolean,
  ) => React.ReactNode;
}

const TabComponent: React.FC<Props> = ({
  tabs,
  mainClassName = 'flex justify-center text-base font-bold items-center gap-9',
  itemClassName = 'flex justify-center items-center text-center text-white hover:text-zinc-500',
  activeClassName = 'flex justify-center items-center text-center text-white text-zinc-500 hover:text-zinc-200',
  renderTab,
}) => {
  const { queryParams, setQueryParams } = useQueryParams();

  useEffect(() => {
    if (!queryParams['tab']) {
      setQueryParams({ ...queryParams, tab: tabs[0].key });
    }
  }, [queryParams, setQueryParams, tabs]);

  const handleTabClick = (key: string) => {
    setQueryParams({ ...queryParams, tab: key });
  };

  return (
    <div className={mainClassName}>
      {tabs.map((tab) => {
        const isActive = queryParams['tab'] === tab.key;
        return (
          <div key={tab.key} onClick={() => handleTabClick(tab.key)}>
            {renderTab ? (
              renderTab(tab, isActive)
            ) : (
              <div className={isActive ? activeClassName : itemClassName}>
                {tab.label}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default TabComponent;
